<template>
  <b-modal
    ref="set-markets-modal"
    hide-footer
    title="ตั้งค่าเปิดปิดหวย"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >

    <form
      id="set-markets-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ชื่อใช้งาน"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          :value="username"
        ></b-form-input>
      </b-form-group>

      <table class="table table-sm">
        <thead>
          <tr>
            <th width="70%" class="text-left text-primary">หวย</th>
            <th class="text-center text-primary">เปิด/ปิด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="market in markets" :key="market.marketId">
            <td>{{ market.marketTitle }}</td>
            <td class="text-center">
              <b-form-checkbox
                switch
                v-model="market.isAvailable"
                :class="[market.isAvailable ? 'text-success' : 'text-danger']"
                @change="toggleChange(market)"
              >
                {{ market.isAvailable ? 'เปิด' : 'ปิด' }}
              </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isLoading">บันทึก</b-button>
      </div> -->

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </form>
  </b-modal>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import cAlert from '@/helpers/alert'

export default {
  name: 'SetMarketsModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    groupId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      markets: []
    }
  },
  computed: {
    username() {
      return this.data?.user?.username
    },
    accountId() {
      return this.data?._id
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    loadAccounts() {
      this.isLoading =true
      AccountSettingService.getAccountSettingMarkets(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.markets = response.data.markets.filter((market)=>{
            return market.groupId === this.groupId
          })
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    setData() {
      this.loadAccounts()
    },
    showModal() {
      this.$refs['set-markets-modal'].show()
    },
    hideModal() {
      this.$refs['set-markets-modal'].hide()
    },
    handleSubmit() {
      this.save(this.markets, true)
    },
    toggleChange(item) {
      this.save([item])
    },
    save(markets, hideOnsave = false) {
      this.isLoading =true
      AccountSettingService.updateAccountSettingMarkets(this.accountId, markets)
      .then((response) => {
        if(response.success) {

          if(hideOnsave) {
            this.hideModal()
          }

          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
